@font-face {
  font-family: "Renault Normal";
  src: url("./fonts/NouvelR-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Renault Light";
  src: url("./fonts/NouvelR-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Renault Bold";
  src: url("./fonts/NouvelR-Bold.ttf") format("truetype");
}

#root {
  min-height: 100vh;
  display: flex;
  font-family: "Renault Normal";
}

.row {
  margin: 0px;
  padding: 0px;
}

a {
  text-decoration: none !important;
}

a:hover {
  color: black !important;
}

.bannerHover:hover {
  opacity: 0.5;
}

.navigationBarStyle {
  height: 140px;
  background-color: black;
}

.pro-sidebar {
  height: auto !important;
  text-align: right !important;
}

.pro-sidebar.md {
  min-width: 250px;
  width: 250px;
  position: fixed;
  right: 0;
  height: 100vh !important;
}

.pro-sidebar.collapsed {
  width: 0px !important;
  min-width: 0px !important;
}

.styled-nav {
  color: white;
  font-family: "Renault Bold";
}

.styled-nav:hover {
  color: lightgray !important;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-content {
  background-color: white;
  color: black;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
  border-bottom: 1px solid rgba(173, 173, 173, 0.2);
  background-color: white;
}

.card {
  transition: box-shadow 0.3s ease-in-out !important;
}

.card:hover {
  box-shadow: 0 0 0.5rem 0.5rem rgba(0, 0, 0, 0.35) !important;
}

.badge {
  transition: box-shadow 0.1s ease-in-out !important;
}

.badge:hover {
  box-shadow: 0 0 0.1rem 0.1rem rgba(0, 0, 0, 0.3) !important;
}

.react-datepicker__time-list-item--disabled {
  display: none;
}

.react-datepicker__input-container {
  display: none !important;
}

.btn:focus {
  box-shadow: 0 0 0 0.2rem #efdf00 !important;
}

.input-error {
  border-color: #fc8181 !important;
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25) !important;
}

.errorMessage {
  color: #fc4444;
  font-size: 0.8rem;
  margin-top: 0.25rem;
}

button:disabled {
  background-color: #e2e8f0 !important;
  color: black !important;
  cursor: not-allowed !important;
  opacity: 0.35 !important;
}

.footerCustomStyleTop {
  min-height: 20rem;
  background-color: #212121;
  color: white;
}

.footerCustomStyleBottom {
  background-color: black;
}

.rowColorOpacity {
  background-color: rgba(131, 131, 131, 0.144);
}

.badgeStyle {
  background-color: rgb(196, 195, 195);
  font-size: 15px !important;
  color: black !important;
}

.modal-large .modal-dialog {
  max-width: 90% !important;
  height: auto;
}

.modal-large .modal-content {
  height: auto;
}

.page-link {
  color: black !important;
}

.page-item.active .page-link {
  background-color: black !important;
  color: white !important;
  border: black;
  border-bottom: black 2px solid;
  border-color: black !important;
}

/* custums arrows para el carousel empieza*/

.owl-carousel .nav-btn {
  height: 27px;
  position: absolute;
  width: 26px;
  cursor: pointer;
  top: 200px !important;
}

.owl-carousel .owl-prev.disabled,
.owl-carousel .owl-next.disabled {
  pointer-events: none;
  opacity: 0.2;
}

.owl-carousel .prev-slide {
  background-color: black;
  left: 0px;
  border-radius: 15px;
  color: white !important;
}
.owl-carousel .next-slide {
  background-color: black;
  border-radius: 15px;
  right: 0px;
  color: white !important;
}
.owl-carousel .prev-slide:hover {
  background-position: 0px -53px;
}
.owl-carousel .next-slide:hover {
  background-position: -24px -53px;
}

.owl-carousel {
  z-index: 0 !important;
}

/* custums arrows para el carousel termina*/

.carouselHeight {
  height: 90vh !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.carouselItemHeight {
  height: 75vh !important;
  object-fit: cover;
}

.carouselCaption {
  /* background: rgba(0, 0, 0, 0.3); */
  position: absolute;
  top: 3% !important;
  left: 5% !important;
  bottom: auto !important;
  right: auto !important;
  font-family: "Renault Bold";
  line-height: 1.5;
  padding: 1% 3% 0 !important;
  font-size: 3rem !important;
}

.itemCarouselButtonStyle {
  border-radius: 0px !important;
  background-color: #ffffff !important;
  border: 1px solid black !important;
  color: black !important;
  padding: 10px 15px !important;
}

.linearGradientBackground {
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.425) 0%,
    rgba(255, 255, 255, 0) 69%
  );
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.boxHomeStyle {
  border: 1px solid lightgray;
  height: 10vh;
  margin-top: 20px;
  border-radius: 1px;
}

.boxHomeStyle:hover {
  box-shadow: 0 0 0 0.2rem #efdf00 !important;
}

.textoUsados {
  position: absolute;
  top: 25% !important;
  left: 5% !important;
  bottom: auto !important;
  right: auto !important;
  font-family: "Renault Bold";
  font-size: 4rem;
  padding: 0% 3% 0 !important;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}

.boxUsados {
  position: absolute;
  top: 70% !important;
  left: 8% !important;
  bottom: auto !important;
  right: auto !important;
  font-family: "Renault Normal";
  font-size: 1.3rem;
  padding: 1% 3% 1% !important;
  color: black;
  background-color: white;
}

.carouselCaptionFooter {
  background-color: black;
  color: white;
  height: 15vh;
}

.carouselCaptionDescription {
  padding-top: 2rem;
  font-size: 1.7vh;
  font-family: "Renault Bold";
}

.carousel-indicators {
  z-index: 2 !important;
}

.borderRightOrderProcess {
  border-right: 1px solid black;
}

.textoAccesorios {
  position: absolute;
  top: 25% !important;
  left: 5% !important;
  bottom: auto !important;
  right: auto !important;
  font-family: "Renault Bold";
  font-size: 4rem;
  padding: 1% 3% 0 !important;
  color: white;
}

.textoServicios {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 25% !important;
  left: 5% !important;
  bottom: auto !important;
  right: auto !important;
  font-family: "Renault Bold";
  font-size: 4rem;
  padding: 1% 3% 1% !important;
  color: white;
}

.fade-in {
  animation: fadeIn ease-in-out 1s;
  -webkit-animation: fadeIn ease-in-out 1s;
  -moz-animation: fadeIn ease-in-out 1s;
  -o-animation: fadeIn ease-in-out 1s;
  -ms-animation: fadeIn ease-in-out 1s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.marcaWidth {
  width: 70px;
}

.hf {
  position: absolute;
  top: 85% !important;
  left: 1% !important;
  bottom: auto !important;
  right: auto !important;
  font-family: "Renault Bold";
  font-size: 45px;
  padding: 1% 3% 0 !important;
  color: white;
}

.carouselWithStickyNavbar {
  margin: 0 0 140px 0 !important;
}

@media (max-width: 530px) {
  .carouselHeight {
    height: 70vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .carouselItemHeight {
    height: 50vh !important;
    object-fit: cover;
  }
  .smNoPadding {
    padding: 0px !important;
  }
  .carouselCaption {
    /* background: rgba(0, 0, 0, 0.3); */
    position: absolute;
    top: 5% !important;
    left: 5% !important;
    padding: 3% 3% 0% 3% !important;
    bottom: auto !important;
    right: auto !important;
    font-size: 2rem !important;
  }
  .carouselCaptionFooter {
    background-color: black;
    color: white;
    height: 20vh;
  }
  .textoUsados {
    position: absolute;
    top: 2% !important;
    left: 5% !important;
    bottom: auto !important;
    right: auto !important;
    font-family: "Renault Bold";
    font-size: 2rem;
    padding: 0% 3% 0 !important;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .hf {
    position: absolute;
    top: 80% !important;
    left: 5% !important;
    bottom: auto !important;
    right: auto !important;
    font-family: "Renault Bold";
    font-size: 15px;
    padding: 1% 3% 0 !important;
    color: white;
  }

  .boxUsados {
    position: absolute;
    top: 70% !important;
    left: 8% !important;
    bottom: auto !important;
    right: auto !important;
    font-family: "Renault Normal";
    font-size: 1rem;
    padding: 2% 4% 2% !important;
    color: black;
    background-color: white;
  }

  .borderRightOrderProcess {
    border-right: 0px !important;
    border-bottom: 1px solid lightgray;
  }

  .textoAccesorios {
    position: absolute;
    top: 15% !important;
    left: 5% !important;
    bottom: auto !important;
    right: auto !important;
    font-family: "Renault Bold";
    font-size: 2rem;
    padding: 1% 3% 0 !important;
    color: white;
  }

  .textoServicios {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 25% !important;
    left: 1% !important;
    bottom: auto !important;
    right: auto !important;
    font-family: "Renault Bold";
    font-size: 3rem;
    padding: 1% 3% 1% !important;
    color: white;
  }
}

@media (max-width: 768px) {
  .pro-sidebar.md {
    height: 100vh !important;
  }
}

@media (max-width: 960px) {
  .modal-large .modal-dialog {
    margin-left: 5%;
  }
  .navigationBarStyle {
    height: 60px;
    background-color: black;
  }
  .marcaWidth {
    width: 30px;
  }
  .carouselWithStickyNavbar {
    margin: 59px 0 0 0 !important;
  }
}

.circle {
  height: 25px;
  width: 25px;
  line-height: 0px;
  border-radius: 50%;
  background-color: #fff;
  position: relative;
  left: 10px;
  top: 10px;
  -webkit-transition: height 0.25s ease, width 0.25s ease;
  transition: height 0.25s ease, width 0.25s ease;

  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.circle i {
  margin-left: 17px;
  color: #4badf7;
  font-size: 20px;
}

.circle:before,
.circle:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  border: 1px solid #fff;
}

.circle:before {
  -webkit-animation: ripple 2s linear infinite;
  animation: ripple 2s linear infinite;
}
.circle:after {
  -webkit-animation: ripple 2s linear 1s infinite;
  animation: ripple 2s linear 1s infinite;
}

.backgroundStyle {
  background-image: url("../assets/images/secciones/rcs/wall.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 200px;
  color: white;
  font-family: "Renault Bold";
  font-size: "25px";
  flex-direction: column;
}

.backgroundForm {
  background-image: url("../assets/images/secciones/formbackground.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* height: 200px; */
}

@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.75);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    opacity: 0;
  }
}

@keyframes ripple {
  0% {
    transform: scale(1);
  }

  75% {
    transform: scale(1.75);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.shake:hover {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-0.5px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(0.5px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-0.5px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(0.5px, 0, 0);
  }
}

#corouselPosition {
  position: relative;
}
