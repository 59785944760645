.navButton {
  color: #212529;
  padding: 0 0.7rem;
  white-space: nowrap;
  padding-right: 5px;
  border-bottom: 2px solid white;
  font-size: 14px;
}

.navButton:hover {
  font-weight: bold;
  border-bottom: 2px solid #efdf00;
}

.navButtonActive {
  font-weight: bold;
  border-bottom: 2px solid #efdf00;
}

.preFix {
  color: gray;
}

.title {
  font-family: "Renault Bold";
  font-size: 30px;
}

.actionButton:hover {
  color: white !important;
}

.actionButtonBackground {
  background-color: black;
  &:hover {
    background-color: #424242;
  }
}

#modalSize {
  margin: 0;
  padding: 0;
  max-width: 100% !important;
  max-height: 100% !important;
  /* overflow: hidden; */
}

.styledButton {
  border: 1px solid black;
  padding: 7px;
  background-color: white;
  margin: 5px 0 5px 0;
  font-family: "Renault Bold";
}

.navModelosHeight {
  height: auto;
}

@media (min-width: 0px) and (max-width: 999px) {
  #modalSize {
    margin-top: 50% !important;
  }
  .navModelosHeight {
    height: auto;
  }
}
